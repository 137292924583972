import React from "react"
import Layout from "../components/layout"

export default () => (
  <Layout>
    <div class="container">
      <div class="alert alert-success" role="alert">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
        ullamcorper magna quis lectus tempus, eu euismod velit pulvinar. Donec
        et sem elit. Mauris in libero luctus, tincidunt ligula quis, finibus
        magna. Maecenas sed condimentum leo, at tempor augue. Nulla non suscipit
        tortor, sed aliquet justo. Integer eros leo, sagittis malesuada dolor
        eget, vehicula commodo mauris. Fusce rhoncus dui sem, quis fermentum
        tellus tincidunt porttitor. Praesent aliquet sagittis sollicitudin.
        Donec lacinia iaculis nisl nec placerat. Nunc sagittis felis accumsan
        turpis porta pellentesque. Proin at nulla tempus sapien porttitor
        volutpat. Aenean pellentesque nulla nec blandit ultricies. Lorem ipsum
        dolor sit amet, consectetur adipiscing elit. Phasellus lobortis auctor
        lacus, vel tempus tortor condimentum non. In ipsum ipsum, euismod non
        metus iaculis, consequat auctor tortor. Duis nisi sapien, pulvinar ac
        aliquam a, egestas ac mi. Nullam tincidunt ex neque, in tincidunt tortor
        consectetur eu. Maecenas dapibus molestie tellus, sit amet tincidunt
        erat. Donec non felis non risus tempor varius vitae non libero. Sed et
        est in eros ullamcorper pellentesque. Ut vel leo erat. Donec varius
        sapien ac hendrerit suscipit. In vulputate aliquet felis, quis ornare
        lacus porta eu. Donec et mi sit amet sem sollicitudin malesuada. Sed
        commodo elit felis, nec mattis risus elementum sit amet. Etiam ornare
        diam consequat, faucibus sem eget, congue tortor. Nullam ac mollis
        risus, eleifend varius dolor. Nunc auctor justo quis nulla mollis, sed
        hendrerit nibh euismod. Nulla hendrerit, tortor et tristique iaculis,
        felis nibh varius sapien, eu suscipit purus neque et eros. Vivamus
        accumsan libero quis elit sollicitudin, eget sodales massa placerat.
        Maecenas ultricies mollis volutpat. Etiam justo lectus, imperdiet in
        velit finibus, euismod posuere ante. Nullam vulputate facilisis est
        lacinia pellentesque. Aliquam porttitor nibh eu ex interdum sodales.
        Suspendisse potenti. Nullam vehicula blandit nulla, eu porttitor ligula
        semper eget. Nullam vitae aliquet mauris, ut cursus ante. Nam ut
        faucibus nibh, dapibus finibus risus. Praesent molestie velit a ligula
        sodales mollis. Suspendisse potenti. Orci varius natoque penatibus et
        magnis dis parturient montes, nascetur ridiculus mus. Proin tellus
        metus, ultrices ac augue id, consequat ornare mauris. Praesent accumsan
        lectus a risus consequat tristique. Etiam venenatis, nunc in dignissim
        euismod, tellus augue pulvinar sapien, at ornare velit mauris a nisi.
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec lacinia
        enim ac nibh rutrum, a semper lectus porta. Aenean tristique scelerisque
        mi, at vehicula sem maximus ut. Etiam leo risus, placerat vel arcu a,
        varius ultrices nisl. Maecenas lacinia mauris quis ipsum tristique
        dapibus. Nunc scelerisque at justo nec egestas. Etiam mattis porttitor
        euismod. Maecenas at arcu sit amet augue mattis pellentesque a eu elit.
        Cras ut diam lobortis, interdum metus nec, finibus tortor. Aenean
        sollicitudin rutrum urna id varius. Curabitur finibus orci vitae dui
        dapibus, a venenatis libero gravida. Sed blandit faucibus sem, in
        sagittis metus efficitur a. Proin varius in risus at aliquet. Donec
        ultrices turpis sit amet augue rhoncus, ut volutpat lectus hendrerit. In
        urna quam, venenatis a nulla non, consequat tempus elit.
      </div>
    </div>
  </Layout>
)
